import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { NotesPageInterface } from "../@types/pages/notes"
import ListContainer from "../components/posts/listContainer"
import Note from "../components/notes/note"

const NotesPage: React.FC<NotesPageInterface> = ({ data }) => {
  return (
    <Layout>
      <SEO title={"Notes"} />
      <ListContainer title={"Notes"}>
        {data.allNotesJson.edges.map(({ node }, i) => (
          <Note
            key={i}
            avatar={data.site.siteMetadata.profileImage.childImageSharp.fixed}
            content={node.content}
            slug={node.fields.slug}
            published={node.published}
            permalink={node.fields.permalink}
          />
        ))}
      </ListContainer>
    </Layout>
  )
}

export default NotesPage

export const query = graphql`
  query {
    allNotesJson(filter: {}, sort: { order: DESC, fields: published }) {
      edges {
        node {
          content
          published
          fields {
            slug
            permalink
          }
        }
      }
    }
    site {
      siteMetadata {
        profileImage {
          childImageSharp {
            fixed(width: 38) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
